import * as React from "react";
import Section from "@components/Section";
import {useColorMode} from "theme-ui";

import {Box, Text} from "rebass";
import AppRegistry from "../meta/AppRegistry";
import {TypedAnimation} from "@digitaltransformation/qone.pages.layout";
import NewsletterForm from "../components/funnel/NewsletterForm";

interface ICoverContent {
    fill: string,
}

class CoverContent extends React.Component<ICoverContent> {
    public render() {
        return (
            <>
                <Section relative>
                    <Box style={{
                        padding: '10vh 0 10vh 0',
                    }}>
                        <Text
                            style={{
                                padding: '1em 0',
                                fontWeight: 900,
                                fontSize: '1em',
                                color: this.props.fill,
                                textTransform: 'uppercase'
                            }}
                        >
                            {AppRegistry.Tags.Header}
                        </Text>
                        <TypedAnimation
                            content={AppRegistry.Tags.Compose}
                            style={{
                                padding: '1em 0',
                                color: this.props.fill,
                                fontSize: '3em',
                                fontWeight: 100,
                            }}
                        />
                        <Box
                            width={'5em'}
                            height={'0.25em'}
                            style={{
                                margin: '1em 0',
                                borderRadius: '5px',
                                background: 'linear-gradient(to right, #1fa2ff, #12d8fa)'
                            }}
                        />
                        <Text
                            style={{
                                padding: '1em 0',
                                maxWidth: '25em',
                                color: this.props.fill,
                                fontSize: '1.25em',
                                fontWeight: 100,
                                lineHeight: '1.25'
                            }}
                        >
                            {AppRegistry.Description.Hero}
                        </Text>
                        {/* <NewsletterForm fill={this.props.fill}/> */}
                    </Box>
                </Section>
            </>
        )
    }
}

const StateWrapper = component => props => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === 'dark';
    const fill = isDark ? "#fff" : "#000";

    return <CoverContent {...props} fill={fill}/>
};

export default StateWrapper(CoverContent);
