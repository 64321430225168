import React from 'react';
import styled from '@emotion/styled';

import Section from '@components/Section';
import SEO from '@components/SEO';
import Layout from '@components/Layout';
import Paginator from '@components/Navigation/Navigation.Paginator';
import SocialLinks from '@components/SocialLinks';

import ArticlesHero from '../sections/articles/Articles.Hero';
import ArticlesList from '../sections/articles/Articles.List';

import MainCoverImpl from '../sections/cover/MainCoverImpl';
import CoverContent from '../../../content/CoverContent';
import AboutContent from '../../../content/AboutContent';
import CallToAction from '../../../components/funnel/CallToAction';
import ArticlesTitle from '../components/Section/Articles.Title';
import CloudPartnerContent from '../../../content/CloudPartnerContent';
import SpanContent from '../../../content/SpanContent';
// import DocumentContent from "../../../content/DocumentContent";
// import Subscription from "../components/Subscription";

import { Box } from '@rebass/grid';
import { graphql, StaticQuery } from 'gatsby';

const contentQuery = graphql`
  query {
    allSite {
      edges {
        node {
          siteMetadata {
            name
            social {
              url
              name
            }
          }
        }
      }
    }

    allImageSharp(
      filter: {
        fluid: {
          originalName: {
            in: ["content:static:pdf:featured", "seo:articles:template:preview"]
          }
        }
      }
    ) {
      edges {
        node {
          id
          fluid {
            originalName
            srcWebp
          }
        }
      }
    }
  }
`;

interface IArticlesProps {
  location: any;
  pageContext: any;
}

class ArticlesPage extends React.Component<IArticlesProps> {
  public render() {
    let { location, pageContext } = this.props;
    const articles = pageContext.group;
    const authors = pageContext.additionalContext.authors;

    return (
      <Layout>
        <MainCoverImpl>
          <CoverContent />
        </MainCoverImpl>
        <Section narrow>
          <ArticlesHero authors={authors} />
          <ArticlesList articles={articles} alwaysShowAllDetails={true} />
          <ArticlesPaginator show={pageContext.pageCount > 1}>
            <Paginator {...pageContext} />
          </ArticlesPaginator>
        </Section>
      </Layout>
    );
  }
}

export default ArticlesPage;

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${(p) => p.show && `margin-top: 95px;`}
`;
